import { createStore } from 'redux'

const nodeConfig = JSON.parse(localStorage.getItem('nodeConfig'))
const initialState = {
    sidebarShow: true,
    farmingTales: {
        isPrepare: false,
        autoHarvest: false,
        waxJS: null,
        walletUsername: '',
        isHarvesting: false,
        balance: {
            SEST: null,
        },
        canClaim: {
            egg: 0,
            sest: 0,
            tomato: 0,
        },
        nft: {
            templates: [],
            schemas: [],
        },
        nftData: [],
        to_claim: 0,
    },
    autoBuying: {
        templateArray: [],
    },
    nodeConfig: nodeConfig || {
        'farming-tales': 'https://wax.greymass.com',
        'auto-panda': 'https://chain.wax.io',
        'auto-roa': 'https://wax.greymass.com',
        'auto-train': 'https://api.wax.alohaeos.com',
        'auto-drop': 'https://api.wax.alohaeos.com',
        'auto-ws': 'https://api.wax.alohaeos.com',
        'auto-goldmand': 'https://api.wax.alohaeos.com',
        'auto-trading': 'https://api.wax.alohaeos.com',
        'auto-trading-2': 'https://api.wax.alohaeos.com',
        'auto-ageoffarming': 'https://api.wax.alohaeos.com',
        'auto-dragons': 'https://api.wax.alohaeos.com',
        'auto-diggers-world': 'https://chain.wax.io',
        'auto-farmersworld': 'https://api.waxeastern.cn',
    },
}

const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest }
        default:
            return state
    }
}

const store = createStore(changeState)
export default store
